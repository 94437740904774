import { size, split, join, pullAt } from 'lodash'

export const numTrans = (number) => {
  const _length = size(split(number, ''))
  if (_length >= 5) {
    let _number = split(number, '')
    pullAt(_number, [_length - 2, _length - 1])
    _number.splice(size(_number) - 2, 0, ',')
    return `${join(_number, '')}万`
  }
  return number
}

/**
 * 节流适用场景：表单提交
 * @param {function} handler 进行节流的函数
 * @param {number} wait 等待时间ms
 */
export function throttleFn() {
  var lastTime = 0
  return function (handler, wait = 1000) {
    var nowTime = new Date().getTime()
    if (nowTime - lastTime > wait) {
      handler && handler()
      lastTime = nowTime
    }
  }
}

/**
 * 防抖适用场景：搜索框
 * @param {function} handler 进行防抖的函数
 * @param {number} delay 等待时间ms
 */
export function debounceFn() {
  var timer = null
  return function (handler, delay = 1000) {
    clearTimeout(timer)
    timer = setTimeout(function () {
      handler && handler()
    }, delay)
  }
}

/**
 * WGS84转GCJ02，高德地图和腾讯地图坐标系是GCJ02
 */
export const wgs84togcj02 = (lng, lat) => {
  var dlat = transformlat(lng - 105.0, lat - 35.0)
  var dlng = transformlng(lng - 105.0, lat - 35.0)
  var radlat = (lat / 180.0) * PI
  var magic = Math.sin(radlat)
  magic = 1 - ee * magic * magic
  var sqrtmagic = Math.sqrt(magic)
  dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
  dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
  const mglat = lat + dlat
  const mglng = lng + dlng
  return [mglng, mglat]
}

// 定义一些常量
var PI = 3.1415926535897932384626
var a = 6378245.0
var ee = 0.00669342162296594323

/**
 * 经度转换
 * @param { Number } lng
 * @param { Number } lat
 */
function transformlat(lng, lat) {
  var ret =
    -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng))
  ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
  ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0
  ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0
  return ret
}

/**
 * 纬度转换
 * @param { Number } lng
 * @param { Number } lat
 */
function transformlng(lng, lat) {
  var ret =
    300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
  ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
  ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0
  ret += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0
  return ret
}

/**
 * 递归遍历标签列表获取三级标签 status=2   获取二级标签 status=1
 * @param {Array} data
 * @param {Function} cb
 */
export function traverseTagsTree(data, status = '2', cb) {
  if (!data || !data.length) {
    return
  }
  for (let i = 0; i < data.length; i++) {
    if (data[i].status == status) {
      cb && cb(data[i])
      continue
    }
    if (data[i].children && data[i].children.length > 0) {
      traverseTagsTree(data[i].children, status, cb)
    }
  }
}

/**
 * 获取图片地址
 * @param {String} uuid
 */
export function getImgSrc(uuid) {
  if (!uuid) {
    return ''
  }
  if (uuid && uuid.startsWith('http')) {
    return uuid
  }
  return `${process.env.VUE_APP_IMG_URI}/download_self?uuid=${uuid}`
}
