<template>
  <div class="map whole-w-h">
    <el-amap id="amap" :zoom="15" @init="initMap" mapStyle="amap://styles/darkblue">
      <!-- <el-amap-polygon
        :path="smPolygons.path"
        :fill-color="smPolygons.color"
        :fill-opacity="0"
        :stroke-weight="8"
        :stroke-color="smPolygons.color"
        :stroke-opacity="0.8"
        :z-index="99"
      /> -->
      <el-amap-polygon
        v-for="(item, idx) in wgPolygons"
        :key="`polygon${item.text + idx}`"
        :path="item.path"
        :vid="idx"
        :fill-color="item.hover ? item.hoverFillColor : item.fillColor"
        :stroke-color="item.hover ? item.hoverStrokeColor : item.strokeColor"
        :stroke-weight="item.hover ? 4 : 2.5"
        :fill-opacity="item.hover ? item.hoverFillOpacity : item.fillOpacity"
        :stroke-opacity="1"
        :z-index="item.zIndex || 100"
        @mouseover="item.hover = true"
        @mouseout="item.hover = false"
      />
      <el-amap-text
        v-for="(item, idx) in wgPolygons"
        :key="`text${item.text + idx}`"
        :text="item.text"
        :offset="[-10, -30]"
        text-align="left"
        vertical-align="top"
        :position="item.center"
      />
      <el-amap-marker
        v-for="(item, idx) in markerArr"
        :key="`marker${idx}`"
        :position="item.position"
        :icon="item.icon"
        anchor="bottom-center"
        :z-index="102"
      />
      <el-amap-layer-satellite />
    </el-amap>
    <div class="mask left-mask"></div>
    <div class="mask right-mask"></div>
    <div class="map-menu">
      <div
        v-for="item in MENU"
        :key="`comm-${item.class}`"
        class="menu-btn"
        @click="mapClick(item.class)"
      >
        <svg-icon :icon-class="item.class" />
        <div class="right">
          {{ item.label }}
          <span v-if="item.label != '清空图层'">：</span>
          <span class="num" v-if="item.label != '清空图层'">{{ item.num }} </span>
          <span v-if="item.total && item.label != '清空图层'">/{{ item.total }}</span>
          <span>{{ item.unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SM_POLYGONS, WANGGE_POLYGONS, MAP_CLICK_ARR } from './data'
import rubbishIcon from '@/assets/svg/rubbish.svg'
import goverIcon from '@/assets/svg/gover.svg'
import videoIcon from '@/assets/svg/video.svg'
import { wgs84togcj02 } from '@/utils/index'

// 坐标系转换，WGS84转GCJ02，高德地图和腾讯地图坐标系是GCJ02
let smPolygons = {
  color: SM_POLYGONS.color,
  path: SM_POLYGONS.path.map((item) => wgs84togcj02(item[0], item[1]))
}
let wgPolygons = WANGGE_POLYGONS.map((item) => {
  return {
    ...item,
    path: item.path.map((ite) => wgs84togcj02(ite[0], ite[1])),
    text: item.text,
    center: wgs84togcj02(item.center[0], item.center[1])
  }
})

export default {
  data() {
    return {
      smPolygons,
      wgPolygons,
      MENU: [],
      map: null,
      mapClickActiveArr: [],
      markerArr: []
    }
  },
  watch: {
    $route: {
      handler(val) {
        // 地图图例数据
        let menu = MAP_CLICK_ARR[val.name] || []
        this.MENU = [...menu]
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    initMap(o) {
      this.map = o
      this.map.setCenter([121.536223, 31.108972])
    },
    mapClick(name) {
      if (this.mapClickActiveArr.includes(name)) {
        this.mapClickActiveArr.splice(this.mapClickActiveArr.indexOf(name), 1)
      } else {
        this.mapClickActiveArr.push(name)
        if (name == 'map-close') {
          this.mapClickActiveArr = []
          this.markerArr = []
        } else if (name == 'map-warning') {
          this.markerArr.push(
            { position: [121.52776, 31.104896], icon: rubbishIcon },
            { position: [121.527836, 31.111474], icon: rubbishIcon }
          )
        } else if (name == 'map-dang') {
          this.markerArr.push({ position: [121.532642, 31.109012], icon: goverIcon })
        } else if (name == 'map-video') {
          this.markerArr.push(
            { position: [121.534273, 31.108976], icon: videoIcon },
            { position: [121.542255, 31.109802], icon: videoIcon }
          )
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/style/variables.less');
@headerHeight: 108px;
::v-deep .amap-logo {
  display: none;
  opacity: 0 !important;
}
::v-deep .amap-copyright {
  opacity: 0;
}
::v-deep .amap-overlay-text-container {
  color: #ffffff;
  background: rgba(18, 77, 111, 0.5);
  font-size: 14px;
}

::v-deep .amap-icon img {
  left: 0;
}

.map {
  width: 100%;
  height: calc(100vh - @headerHeight);
  .position(absolute,108px,auto,0);
  .map-menu {
    width: 160px;
    .position(absolute,20px,auto,30.3%,auto,1);
    .menu-btn {
      .flex(row,flex-start, center);
      font-size: 12px;
      font-weight: 500;
      color: #e2e8ed;
      margin-bottom: 1px;
      cursor: pointer;
      &:hover {
        svg {
          animation: turnOpacity 2s ease-in-out infinite;
        }
      }
      .right {
        width: 116px;
        height: 30px;
        background: rgba(5, 37, 40, 0.8);
        border-radius: 2px;
        line-height: 30px;
        padding-left: 8px;
        margin-left: 2px;
        text-align: left;
        .num {
          color: #ffb404;
        }
      }
    }
    svg {
      margin: auto;
      width: 40px !important;
      height: 40px !important;
    }
    @keyframes turnOpacity {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.5;
      }
    }
  }
  .mask {
    width: 30%;
    height: 100%;
    .position(absolute,0,auto);
    &.left-mask {
      left: 0;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
    }
    &.right-mask {
      right: 0;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
    }
  }
}
</style>
