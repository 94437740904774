export const MAP_CLICK_ARR = {
  PartyBuildView: [
    // { label: '累计报警', num: 50, total: '', unit: '件', class: 'map-warning' },
    { label: '村委党组织', num: 1, total: '', unit: '个', class: 'map-dang' }
    // { label: '摄像头', num: 34, total: 42, unit: '', class: 'map-video' },
    // { label: '清空图层', num: '', total: '', unit: '', class: 'map-close' }
  ]
}

export const SM_POLYGONS = {
  color: '#2b83f9',
  path: [
    [121.51885357227741, 31.116316143155473],
    [121.518675164868, 31.117018736008944],
    [121.51960288338864, 31.117263114913655],
    [121.51942447598168, 31.117965700758532],
    [121.52763121674616, 31.120134519905477],
    [121.52809507600529, 31.11897374962487],
    [121.53073550564358, 31.119981787784695],
    [121.53148481675481, 31.118698828268435],
    [121.53102095749574, 31.11848499999617],
    [121.53123504638359, 31.118210077224035],
    [121.53255526120392, 31.118698828268435],
    [121.53301912046305, 31.11872937512463],
    [121.53312616490825, 31.118210077224035],
    [121.53316184638913, 31.117568587658496],
    [121.53341161676036, 31.11659107141348],
    [121.53362570565065, 31.115919023155016],
    [121.53441069824527, 31.11607176181265],
    [121.53508864639463, 31.116316143155473],
    [121.53505296491375, 31.116988188602278],
    [121.53583795750825, 31.117171472898008],
    [121.53583795750825, 31.117843512288758],
    [121.5380858908469, 31.11817953019974],
    [121.5380858908469, 31.116865998875127],
    [121.53826429825625, 31.11601066637887],
    [121.53879952047959, 31.11561354510036],
    [121.539477468629, 31.1143305265378],
    [121.54022677974268, 31.113414074089633],
    [121.54090472789204, 31.113016941948104],
    [121.54126154270847, 31.111794986476653],
    [121.54350947604706, 31.11206992782911],
    [121.5447583279032, 31.11265035695878],
    [121.5456503649429, 31.112833649630403],
    [121.54597149827589, 31.112008829819715],
    [121.54479400938408, 31.11167279006463],
    [121.54511514271957, 31.110389718240583],
    [121.54515082420045, 31.109625976778943],
    [121.54457992049618, 31.109442677914345],
    [121.54390197234432, 31.10947322774919],
    [121.54311697974975, 31.109656526554787],
    [121.5426174390073, 31.1101453216407],
    [121.54236766863607, 31.110450817291948],
    [121.54208221678397, 31.110817410774587],
    [121.54158267604151, 31.110817410774587],
    [121.54033382418783, 31.110756311959122],
    [121.53912065381263, 31.110542465794808],
    [121.53819293529205, 31.110328619149854],
    [121.53762203158777, 31.11026752001979],
    [121.537871801959, 31.109595426992215],
    [121.53840702418239, 31.109595426992215],
    [121.53879952047959, 31.109045529158877],
    [121.53840702418239, 31.108740028986944],
    [121.53847838714421, 31.107976274255122],
    [121.53483887602346, 31.10712086166265],
    [121.5342322908383, 31.108373427482093],
    [121.53348297972457, 31.109656526554787],
    [121.53301912046305, 31.110695213104307],
    [121.53130640934785, 31.1100842223927],
    [121.53098527601236, 31.111122904263183],
    [121.52820212045043, 31.110206420849394],
    [121.52884438711897, 31.109045529158877],
    [121.52813075748617, 31.10843452783105],
    [121.52820212045043, 31.10800682456282],
    [121.52870166119288, 31.10773187144393],
    [121.52941529082318, 31.107792972205715],
    [121.52970074267779, 31.10739581655085],
    [121.52327807599272, 31.104768437296528],
    [121.52160106389368, 31.103943573925278],
    [121.52010244166883, 31.10339364336049],
    [121.51924608611, 31.10293536545757],
    [121.51878222685087, 31.10366860904095],
    [121.52177947130315, 31.105104527992893],
    [121.52124424907976, 31.107090337574164],
    [121.5206733453731, 31.10886225567741],
    [121.52035221204, 31.109717652582574],
    [121.5205663009279, 31.110389744743856],
    [121.51981698981666, 31.11277257861518],
    [121.51928176759094, 31.114849872165863],
    [121.51881790833181, 31.116346717280123]
  ]
}

export const WANGGE_POLYGONS = [
  {
    hover: false,
    fillColor: '#934FA0',
    fillOpacity: 0.17,
    strokeColor: '#ff00f9',
    hoverFillColor: '#E25CFB',
    hoverStrokeColor: '#d700ff',
    hoverFillOpacity: 0.55,
    path: [
      [121.5186751695062, 31.11701875359425],
      [121.51878221394895, 31.116377255978037],
      [121.51942448061737, 31.114483285337244],
      [121.52013811025012, 31.11191720031762],
      [121.52188650284626, 31.1123143370597],
      [121.52249308803391, 31.112680923347583],
      [121.52349216951876, 31.113078056894736],
      [121.52499079174373, 31.11381122215562],
      [121.52591851026676, 31.11423889927663],
      [121.52466965841063, 31.117385321714792],
      [121.52420579914912, 31.11851556450614],
      [121.52381330285186, 31.119187594379966],
      [121.52142264358719, 31.118576658327584],
      [121.51985265839807, 31.1182100948091],
      [121.51942448061737, 31.118026812517968],
      [121.51956720654346, 31.117232585169873],
      [121.51863948802281, 31.117049300992136]
    ],
    text: '第四网格',
    center: [121.520862, 31.115213]
  },
  {
    hover: false,
    fillColor: '#98580A',
    strokeColor: '#ff9400',
    fillOpacity: 0.13,
    hoverFillColor: '#E3830F',
    hoverStrokeColor: '#ff8c00',
    hoverFillOpacity: 0.48,
    path: [
      [121.52506215470788, 31.11390286741549],
      [121.52541896952425, 31.113139154216],
      [121.52634668804495, 31.11353628584621],
      [121.52713168063946, 31.11225323920756],
      [121.52770258434379, 31.111061823230727],
      [121.52820212508624, 31.110267537605296],
      [121.52880871027384, 31.109106646662056],
      [121.52820212508624, 31.10849564572753],
      [121.52809508064104, 31.108129043280158],
      [121.5286659843477, 31.107792989792756],
      [121.52934393249717, 31.107762439416234],
      [121.52966506583266, 31.1074874855897],
      [121.5221005917366, 31.10431018361558],
      [121.521494006549, 31.104004668207267],
      [121.51974561395281, 31.103240875387428],
      [121.51921039172953, 31.102996460388184],
      [121.51885357691316, 31.103729703499823],
      [121.52167241395836, 31.10510451907558],
      [121.52095878432567, 31.108037392446604],
      [121.52045924358322, 31.1096259943656],
      [121.5204949250666, 31.110511933890507],
      [121.52020947321205, 31.111978298385978],
      [121.52231468062695, 31.112528179232882],
      [121.5234208065545, 31.113047508218855],
      [121.52463397692969, 31.11356683436486],
      [121.52506215470788, 31.11384177058575]
    ],
    text: '第五网格',
    center: [121.525965, 31.11643]
  },
  {
    hover: false,
    fillColor: '#18649A',
    strokeColor: '#0094ff',
    fillOpacity: 0.14,
    hoverFillColor: '#3E8DC5',
    hoverStrokeColor: '#3eafff',
    hoverFillOpacity: 0.62,
    path: [
      [121.52677486582314, 31.112803118461954],
      [121.52995051768477, 31.113689028338868],
      [121.52859462138349, 31.119126500951793],
      [121.52802371767928, 31.119065407484257],
      [121.52752417693677, 31.120103991085315],
      [121.52399171026121, 31.119065407484257],
      [121.52588282878344, 31.114208350974337],
      [121.52509783618882, 31.11390286741549],
      [121.52545465100519, 31.113261348740565],
      [121.52627532508063, 31.11356683436486],
      [121.5268462287874, 31.112772569697583]
    ],
    text: '第三网格',
    center: [121.522431, 31.109314]
  },
  {
    hover: false,
    fillColor: '#310558',
    strokeColor: '#a800ff',
    fillOpacity: 0.14,
    hoverFillColor: '#994FDC',
    hoverStrokeColor: '#8700ff',
    hoverFillOpacity: 0.58,
    path: [
      [121.52677486582314, 31.112894764695028],
      [121.52991483620139, 31.11362793137151],
      [121.52902279916407, 31.117293679817152],
      [121.52855893990255, 31.1192181410798],
      [121.53062846583413, 31.120012351812676],
      [121.5314134584288, 31.118729392709582],
      [121.5310923250957, 31.11851556450614],
      [121.5311993695384, 31.118271188827165],
      [121.53244822139459, 31.1186988458535],
      [121.5329120806561, 31.118759939556924],
      [121.53312616954395, 31.1182100948091],
      [121.53312616954395, 31.117721341247915],
      [121.53348298436026, 31.116377255978037],
      [121.5336257102864, 31.115888492978897],
      [121.53430365843582, 31.116041231685642],
      [121.53516001399458, 31.113444640233496],
      [121.53573091769891, 31.111581160238217],
      [121.53305480658207, 31.110756329545595],
      [121.53134209546693, 31.110145339227316],
      [121.53087823620541, 31.111092372544576],
      [121.52820212508624, 31.110267537605296],
      [121.5268462287874, 31.112894764695028]
    ],
    text: '第二网格',
    center: [121.530784, 31.115462]
  },
  {
    hover: false,
    fillColor: '#851B0A',
    strokeColor: '#ff2200',
    fillOpacity: 0.16,
    hoverFillColor: '#F35840',
    hoverStrokeColor: '#ff2200',
    hoverFillOpacity: 0.65,
    path: [
      [121.53576659918224, 31.11164225852282],
      [121.53487456214248, 31.114299995850757],
      [121.53426797695488, 31.116071779398112],
      [121.5349459251043, 31.116377255978037],
      [121.53516001399458, 31.11695765877002],
      [121.53580228066312, 31.117232585169873],
      [121.53587364362738, 31.117874077006334],
      [121.53794316955896, 31.11817954778479],
      [121.5379788510399, 31.11698820618757],
      [121.53829998437294, 31.115980136232224],
      [121.53883520659627, 31.115521919077636],
      [121.53937042882205, 31.114513833551072],
      [121.53994133252627, 31.11381122215562],
      [121.54033382882358, 31.11332244594385],
      [121.54086905104691, 31.113047508218855],
      [121.54133291030843, 31.111886651268193],
      [121.5433310732758, 31.11210049440477],
      [121.54482969550315, 31.11265037454386],
      [121.54568605105953, 31.112833667216492],
      [121.54590013994994, 31.112039396415014],
      [121.54490105846497, 31.111672807650947],
      [121.545043784391, 31.110695230690794],
      [121.54511514735526, 31.109656544141444],
      [121.54461560661281, 31.10944269550002],
      [121.54390197698245, 31.10953434497705],
      [121.54311698438795, 31.109656544141444],
      [121.54226062882918, 31.110511933890507],
      [121.54197517697702, 31.110786878958734],
      [121.5396915621551, 31.110603582334363],
      [121.53812157696592, 31.110389735826047],
      [121.53758635474259, 31.11029808717575],
      [121.53805021400171, 31.109717643664695],
      [121.53844271029897, 31.10953434497705],
      [121.53872816215352, 31.109167746539143],
      [121.53847839178235, 31.10870949650203],
      [121.53844271029897, 31.10809849301178],
      [121.53476751769733, 31.10715142983166],
      [121.53373275473155, 31.109106646662056],
      [121.5329120806561, 31.110695230690794],
      [121.53576659918224, 31.111611709384917]
    ],
    text: '第一网格',
    center: [121.536508, 31.113172]
  },
  {
    hover: false,
    fillColor: '#2378B5',
    strokeColor: '#00b5ff',
    fillOpacity: 0.13,
    hoverFillColor: '#469EDC',
    hoverStrokeColor: '#0095ff',
    hoverFillOpacity: 0.48,
    path: [
      [121.51917471024626, 31.117232585169873],
      [121.51935311765561, 31.11656054145351],
      [121.5241344361873, 31.117874077006334],
      [121.52392034729695, 31.118637752109663],
      [121.51935311765561, 31.117293679817152]
    ],
    zIndex: 101,
    text: '专属网格',
    center: [121.520796, 31.117162]
  },
  {
    hover: false,
    fillColor: '#29751A',
    strokeColor: '#2aff00',
    fillOpacity: 0.21,
    hoverFillColor: '#29751A',
    hoverStrokeColor: '#2aff00',
    hoverFillOpacity: 0.12,
    path: [
      [121.52577578433824, 31.111611709384917],
      [121.52613259915461, 31.110970175228204],
      [121.52670350286132, 31.111122921849585],
      [121.52706031767525, 31.110236988026045],
      [121.52763122138197, 31.109106646662056],
      [121.52834485101232, 31.109381595799874],
      [121.52698895471343, 31.11216159235518],
      [121.52574010285736, 31.111733905876577]
    ],
    zIndex: 101,
    text: '专属网格',
    center: [121.525909, 31.111009]
  },
  {
    hover: false,
    fillColor: '#ED26CA',
    strokeColor: '#ff00d2',
    fillOpacity: 0.21,
    hoverFillColor: '#F448D6',
    hoverStrokeColor: '#ff00d2',
    hoverFillOpacity: 0.41,
    path: [
      [121.52873734730952, 31.111184020429135],
      [121.52859462138349, 31.111611709384917],
      [121.52795235471501, 31.1113673159318],
      [121.52834485101232, 31.110542483381266],
      [121.5286659843477, 31.110695230690794],
      [121.52848757693835, 31.111092372544576]
    ],
    zIndex: 101,
    text: '专属网格',
    center: [121.52845, 31.110264]
  }
]
